import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewContainerRef,
  ViewChild,
  ViewChildren,
  ElementRef
} from '@angular/core';
import { PagedataService } from '../services/pagedata.service';
import { ModalController, NavParams, IonInfiniteScroll, IonContent } from '@ionic/angular';
import { CookieData, LocalStorageData, INSTANT_RESERVATION_BUTTON_NAME } from '../pagedata';
import { ThemeData } from '../interfaces/themeData.interface';
import { SalonData } from '../interfaces/salonData.interface';
import { initSalonData } from '../initData/initData';
import { ParamForReservation } from '../interfaces/paramForReservation.interface';
import { MyPageService } from '../services/myPage.service';
import { WindowService } from '../services/windowService';
import { ShopDetailModalService } from '../services/shopDetailModal.service';
import { NavigationController } from '../services/navigationController';
import { MetaDataController } from '../services/metaDataController';

import { LayoutData } from '../interfaces/layoutData.interface';
import { initLayoutData } from '../initData/initData';
import { NoticeOpenLinkFromAppCom } from '../services/noticeOpenLinkFromAppCom';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { UpdateGoogleAnalyticsService } from '../services/updateGoogleAnalyticsService';
declare var google: any;
import { GoogleMap } from '@angular/google-maps';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { register } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-shoplistmapmodal',
  templateUrl: './shoplistmapmodal.component.html',
  styleUrls: ['./shoplistmapmodal.component.scss']
})
export class ShoplistmapmodalComponent implements OnInit {
  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;
  public parameter: any;
  public isStandAlone: boolean = false;
  public isFavoriteOnly: boolean = false;
  public pageMoveList: any = [];
  browserBackForModalCallbackEvent;

  @ViewChild('mapShopSlider', { static: true }) mapShopSlider: ElementRef;
  @ViewChild('shopListIonContent', { static: false }) shopListIonContent: IonContent;
  @ViewChildren('shopSlide') shopSlideList;
  loadingImage = './assets/images/icons/loading-dot.png';
  layoutData: LayoutData = initLayoutData;
  public brandList: any = [];
  public selectBrandList: any = [];
  public shopListPageData: any;
  public companyData: SalonData = initSalonData;
  public instantReservationButtonName: string = '';
  private dispShopListAll: any;
  private dispShopList: any = [];
  public mapShopList: any;
  public mapShopListBeforeDup: any = [];
  public mapShopListAfterDup: any = [];
  private dispLength = 0;

  public locationLat: any = null; // 現在地表示座標（緯度）
  public locationLon: any = null; // 現在地表示座標（経度）
  public locationRadius: any = null; // 現在地表示座標（経度）
  alreadyShowCurrent: boolean = false;

  public favoriteShopList: any;

  isShowShopList: boolean = true;
  isShowMap: boolean = true;
  activeIndex: number = 0;
  activeShopId: number = 0;
  slideMode: string = null;
  isPrevBtnHide: boolean = false;
  isNextBtnHide: boolean = false;

  isInfiniteScrollDisabled: boolean = false;

  shopListSearchInputValue: string = null;

  searchMapInputKeydownCode: number = null;
  searchMapInputKeyupCode: number = null;
  watchPositionId: number = 0;

  // @ViewChild を追加
  @ViewChild('modalcontent', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;

  themeData: ThemeData = {
    font: null,
    headingStyle: null,
    color: {
      accentColor: null,
      functionalColor: null,
      baseColor: null,
      attentionColor: null
    }
  };

  // 戻る。閉じるボタン：戻るボタン表示フラグ
  isShowBack: boolean = false;
  // 一覧・地図セグメント：表示切り替えモード
  listMapSegmentMode: string = '';
  private subscription: Subscription;

  public markers: ExtendedMarker[] = [];
  shopListModalStyles: any;
  apiLoaded: Observable<boolean>;
  center: google.maps.LatLngLiteral;
  mapOptions: google.maps.MapOptions = {
    zoom: 13,
    gestureHandling: 'greedy',
    mapTypeId: 'roadmap',
    mapTypeControl: false,
    fullscreenControl: false
  };
  @ViewChild(GoogleMap) map: GoogleMap;
  currentPositionIcon: any = {
    url: 'assets/images/icons/map/map_marker_current_position.svg',
    scaledSize: new google.maps.Size(22, 26),
    anchor: new google.maps.Point(11, 26)
  };

  constructor(
    public pds: PagedataService,
    private navigationController: NavigationController,
    private navParams: NavParams,
    private resolver: ComponentFactoryResolver,
    private noticeOpenLinkFromAppCom: NoticeOpenLinkFromAppCom,
    private updateGoogleAnalyticsService: UpdateGoogleAnalyticsService,
    private modalController: ModalController,
    public myPageService: MyPageService,
    private windowService: WindowService,
    public shopDetailModalService: ShopDetailModalService,
    private metaDataController: MetaDataController,
    httpClient: HttpClient,
    private cookieService: CookieService
  ) {
    // モーダル用のブラウザバック処理にthisをバインドする
    this.browserBackForModalCallbackEvent = this.browserBackForModal.bind(this);
    this.isStandAlone = this.pds.getIsStandAloneMode();
    this.favoriteShopList = JSON.parse(this.pds.getData(LocalStorageData.FAVORITE_SALON_ID_LIST));
    this.apiLoaded = httpClient
      .jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyDsk_M3zApNmWSHdHj87k7OUNyKNO5Wlpk', 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  ngOnInit() {
    this.loadData();
    this.parameter = this.navParams.data; //パラメータの受け取り

    // 空の履歴をセットする（ブラウザバックでモーダルを閉じたときに、元のページを再読込させないため）
    history.pushState(null, null, null);
    // ブラウザバックイベントとして、bind(this)したブラウザバックコールバックイベントをセット
    window.addEventListener('popstate', this.browserBackForModalCallbackEvent);

    // イベント登録
    // サービスで共有しているデータが更新されたら発火されるイベントをキャッチする
    this.subscription = this.noticeOpenLinkFromAppCom.sharedDataSource$.subscribe((msg) => {
      // this.openPage(msg);
    });
    // お気に入り登録済みの場合、お気に入り状態で一覧を表示する
    if (this.pds.getData(LocalStorageData.FAVORITE_SALON_ID_LIST)) {
      if (JSON.parse(this.pds.getData(LocalStorageData.FAVORITE_SALON_ID_LIST)).length > 0) {
        this.isFavoriteOnly = true;
      }
    }
    // モーダル起動時の表示モードを判定する（アプリ化：一覧、ブラウザ：地図）
    this.listMapSegmentMode = this.isStandAlone && this.pds.viewMode !== 'browser' ? 'list' : 'map';
  }

  ionViewWillEnter() {
    // cardモーダル表示用のクラスをつける
    this.navigationController.isOpenCardModal = true;
    this.setTheme();
  }

  ionViewDidEnter() {
    this.checkSlide(this.mapShopSlider.nativeElement.children[0].swiper);
    this.slideToFromShopId(this.activeShopId, 0);
  }

  ionViewWillLeave() {
    // cardモーダル表示用のクラスを外す
    this.navigationController.isOpenCardModal = false;
    this.resetTheme();
  }

  ionViewDidLeave() {}

  ngOnDestroy() {
    // 履歴の１つ前（今いるのは空の履歴、１つ前はモーダルを開いた本部のページ）に戻る
    // history.go(-1);
    this.subscription.unsubscribe();
    // ブラウザバックイベントのブラウザバックコールバックイベントを外す
    window.removeEventListener('popstate', this.browserBackForModalCallbackEvent);
  }

  async loadData() {
    var _this = this;
    // 店舗一覧に表示する店舗リストを取得
    this.shopListPageData = await this.pds.getShopListPageData();
    this.companyData = this.shopListPageData.companyData;
    this.layoutData = await this.pds.getLayoutData();
    this.instantReservationButtonName =
      INSTANT_RESERVATION_BUTTON_NAME[this.companyData.instantReservationButtonNameIndex];
    this.brandList = this.shopListPageData.brandList;
    // 表示用の店舗リストに、取得した店舗リストをセットする
    this.dispShopListAll = this.shopListPageData.shopList;
    this.mapShopList = this.shopListPageData.shopList;
    // Cookieに店舗のデータがあればその店舗をお気に入りに登録する
    const addedFavoriteSalon = this.pds.getData(LocalStorageData.ADDED_FAVORITE_SALON) === null ? false : true;
    if (this.pds.isPwaApp && this.pds.getIsCompany() && !addedFavoriteSalon) {
      const exists = this.cookieService.check(CookieData.INITIAL_APP_OPENED_SALON_TITLE);
      let shopName = null;
      if (exists) {
        shopName = this.cookieService.get(CookieData.INITIAL_APP_OPENED_SALON_TITLE);
        // Cookieの保存期限を100年にしているが、ブラウザの制限で短命の場合があるので、ローカルストレージにも保存しておく
        this.pds.setData(LocalStorageData.INITIAL_APP_OPENED_SALON_TITLE, shopName);
      } else {
        // Cookieにない場合、ローカルストレージから店舗名が取れるか試す
        shopName = this.pds.getData(LocalStorageData.INITIAL_APP_OPENED_SALON_TITLE);
      }
      // this.dispShopListAllのaccountNameがshopNameと一致する店舗をお気に入りに登録する
      let salonId = 0;
      if (shopName !== null) {
        for (let i = 0; i < this.dispShopListAll.length; i++) {
          if (this.dispShopListAll[i].accountName === shopName) {
            salonId = this.dispShopListAll[i].id;
            break;
          }
        }
      }
      if (salonId > 0) {
        // お気に入り登録店舗リストに、対象店舗のIDを追加する
        // this.favoriteShopListにsalonIdがない場合のみ追加する
        if (this.favoriteShopList.indexOf(salonId) === -1) {
          this.changeFavorite(salonId, false);
        }
      }
      // お気に入り登録はアプリ初回起動のみで良いので、LocalStorageData.ADDED_FAVORITE_SALONを更新する
      this.pds.setData(LocalStorageData.ADDED_FAVORITE_SALON, true);
    }
    // お気に入り登録されている店舗が存在する場合は、店舗一覧の初期表示を「お気に入りのみ」にする
    if (this.favoriteShopList.length > 0) {
      this.isFavoriteOnly = true;
    }
    // 店舗リストを表示
    this.refreshShopList();
    this.setMap();
    if (this.dispShopListAll.length <= 4) {
      this.isInfiniteScrollDisabled = true;
    }

    this.mapShopListBeforeDup = [];
    this.mapShopListAfterDup = [];
    if (this.mapShopList.length > 3) {
      // 店舗数が３を超える場合は、前後複製要素を作成（２以下は複製要素にスライドすることがないので作成しない）
      for (let i = 0; i < this.mapShopList.length; i++) {
        if (i < 2) {
          this.mapShopListAfterDup.push(this.mapShopList[i]);
        }
        if (i >= this.mapShopList.length - 2) {
          this.mapShopListBeforeDup.push(this.mapShopList[i]);
        }
      }
    }
    // 店舗一覧起動時の店舗は、一覧の先頭店舗IDをセットする
    this.activeShopId = this.mapShopList[0].id;

    // 店舗数からスライドの表示を変更
    if (this.mapShopList.length <= 1) {
      // 店舗数が1以下の場合はlock（スライド機能なし、循環なし）
      this.slideMode = 'lock';
    } else if (1 < this.mapShopList.length && this.mapShopList.length <= 3) {
      // 店舗数が1を超えて、3以下の場合はslide（スライド機能あり、循環なし）
      this.slideMode = 'slide';
      // 前ボタンを非表示にする
      this.isPrevBtnHide = true;
    } else {
      // 店舗一覧数が3を超える場合はloop（スライド機能あり、循環あり）
      this.slideMode = 'loop';
    }

    // スライダーの設定
    let shopSwiper = this.mapShopSlider.nativeElement.children[0];
    let prevBtn = this.mapShopSlider.nativeElement.children[1];
    let nextBtn = this.mapShopSlider.nativeElement.children[2];
    shopSwiper.swiper.params = {
      ...shopSwiper.swiper.params,
      spaceBetween: 10,
      centeredSlides: true,
      breakpointsBase: 'container',
      direction: 'horizontal',
      slideActiveClass: 'activeSlide',
      breakpoints: {
        0: {
          slidesPerView: 1.2
        },
        600: {
          slidesPerView: 2
        }
      }
    };

    if (this.mapShopList.length > 1) {
      // スライダーの次へ、前へボタンのクリックイベントを設定
      shopSwiper.swiper.navigate = {
        nextEl: nextBtn,
        prevEl: prevBtn
      };
      shopSwiper.swiper.navigate.nextEl.addEventListener('click', () => {
        shopSwiper.swiper.slideNext();
      });
      shopSwiper.swiper.navigate.prevEl.addEventListener('click', () => {
        shopSwiper.swiper.slidePrev();
      });
    }

    // スライド変更時に前へ、次へボタンの表示状態を変更
    shopSwiper.swiper.on('activeIndexChange', (swiper) => {
      _this.checkSlide(swiper);
    });
    // スライドの変更後に複製スライドのチェックを行う
    shopSwiper.swiper.on('slideChangeTransitionEnd', function (swiper) {
      const activeIndex = swiper.activeIndex; // アクティブなスライドのインデックス
      // 'loop'モードで複製要素へスライドした場合、元のスライドに戻す
      if (_this.slideMode === 'loop') {
        if (swiper.slides[activeIndex].classList.contains('duplicateSlide')) {
          _this.slideToFromShopId(swiper.slides[activeIndex].dataset['shopId'], 0);
        }
      }
    });
  }

  // ==========================================================
  // 店舗一覧モーダルのヘッダー周り
  // ==========================================================
  // 閉じるボタン処理
  close() {
    this.modalController.dismiss();
    this.clearWatchPosition();
  }

  // 一覧・地図セグメント変更
  changeListMap(listMapSegmentValue) {
    this.listMapSegmentMode = listMapSegmentValue;
  }

  // お気に入り店舗表示切り替え
  changeFavoriteList(btn) {
    // favoriteOnlyクラス（お気に入りのみ表示）が付与されている場合
    if (btn.classList.contains('favorite-only')) {
      // favoriteOnlyクラスを外す（全件表示にする）
      this.isFavoriteOnly = false;
    } else {
      // 付与されていない場合、favoriteOnlyクラスを付与する（お気に入りのみ表示）
      this.isFavoriteOnly = true;
    }

    // 店舗リストを再表示
    this.refreshShopList();
  }

  // モーダル内にページを開く
  openShopDetailModal(shopName, pageName) {
    this.shopDetailModalService.openShopDetailModal(shopName, pageName, {}, this.browserBackForModalCallbackEvent);
  }

  // モーダル表示時のブラウザバック処理
  browserBackForModal() {
    // モーダルの戻る・閉じる処理を実行する
    this.close();
  }

  // googleアナリティクスを設定
  accruePageviewsForGA(pageName: string, shopName: string, requestParams: []) {
    let path = '/' + pageName;
    let params = [];
    if (shopName !== '') {
      params.push(['shopDetail', shopName]);
    }
    if (requestParams !== null && requestParams !== undefined) {
      params = params.concat(Object.entries(requestParams));
    }
    for (let i = 0; i < params.length; i++) {
      if (params[i][1] !== null && params[i][1] !== undefined) {
        let symbol = i === 0 ? '?' : '&';
        path += symbol + params[i][0] + '=' + params[i][1];
      }
    }
    if (pageName === 'shopselect') {
      this.updateGoogleAnalyticsService.accruePageviews(path, false, 'shopselect', shopName);
    } else {
      this.updateGoogleAnalyticsService.accruePageviews(path, false, null, shopName);
    }
  }

  // ==========================================================
  // 店舗一覧　各店舗要素周り
  // ==========================================================
  // 検索フォーム入力時
  inputSearch(value) {
    this.shopListSearchInputValue = value;
    // 店舗リストを再表示
    this.refreshShopList();
  }

  // 業種絞り込みをクリック
  selectBrand(btn, brandId) {
    if (btn.classList.contains('selected')) {
      // クリックした業種を業種選択状態から外す
      this.selectBrandList.splice(this.selectBrandList.indexOf(brandId), 1);
    } else {
      // クリックした業種を業種選択状態にする
      this.selectBrandList.push(brandId);
    }
    // 店舗リストを再表示
    this.refreshShopList();
  }

  // スクロールで追加表示
  infiniteLoadData(e) {
    setTimeout(() => {
      this.appendItems(4);
      e.target.complete();
    }, 500);
  }

  // 各店舗要素追加
  appendItems(number) {
    var originalLength = this.dispLength;
    for (var i = 0; i < number; i++) {
      let shopData = this.dispShopListAll[i + originalLength];
      // 要素追加の店舗が取得できない場合は処理を完了する
      if (!shopData) {
        this.isInfiniteScrollDisabled = true;
        return;
      }
      // 表示リストに該当店舗データを追加する
      this.dispShopList.push(shopData);
      // 表示数のカウントを進める
      this.dispLength++;
    }

    // 追加読み込みにより、上限に達した場合はスクロール読み込みを停止する
    if (this.dispLength === this.dispShopListAll.length) {
      this.isInfiniteScrollDisabled = true;
    }
  }

  // 各店舗お気に入り登録切り替え
  changeFavorite(shopId, isFavorite) {
    if (isFavorite) {
      // お気に入り店舗として登録されている場合
      // お気に入り登録店舗リストの中から、対象店舗のIDを削除する
      this.favoriteShopList.splice(this.favoriteShopList.indexOf(shopId), 1);
    } else {
      // お気に入り店舗として登録されていない場合
      // お気に入り登録店舗リストに、対象店舗のIDを追加する
      this.favoriteShopList.push(shopId);
    }
    // 変更後のお気に入り登録店舗リストをJSON形式でセットする
    this.pds.setData(LocalStorageData.FAVORITE_SALON_ID_LIST, JSON.stringify(this.favoriteShopList));
  }

  refreshShopList() {
    // 店舗表示内容を全削除
    this.dispShopList = [];
    // 表示件数をリセット
    this.dispLength = 0;
    // スクロール読み込みを可能にする
    this.isInfiniteScrollDisabled = false;

    // 表示店舗一覧を全店リストで更新
    this.dispShopListAll = this.shopListPageData.shopList;

    // ▼▼▼お気に入り表示チェック▼▼▼
    // お気に入り店舗表示になっている場合、お気に入り店舗表示用のリストを作成する
    if (this.isStandAlone) {
      if (this.isFavoriteOnly) {
        this.dispShopListAll = [];
        let favoriteShopList = JSON.parse(this.pds.getData(LocalStorageData.FAVORITE_SALON_ID_LIST));
        // お気に入り登録されている店舗のみ、表示店舗リストに追加する
        for (let i = 0; i < this.shopListPageData.shopList.length; i++) {
          var shopId = this.shopListPageData.shopList[i].id;
          if (favoriteShopList.indexOf(shopId) !== -1) {
            this.dispShopListAll.push(this.shopListPageData.shopList[i]);
          }
        }
      }
    }
    // ▼▼▼業種チェック▼▼▼
    // 選択した業種に該当する店舗を表示する
    if (this.selectBrandList.length > 0) {
      let checkBrandShopList = [];
      for (let num in this.selectBrandList) {
        // 選択した業種を登録している店舗リストを取得する
        let selectBrand = this.selectBrandList[num];
        let selectBrandRelSalonList = this.brandList.filter((b) => b.id === selectBrand)[0]['relSalons'];
        // 表示する店舗一覧の中から、選択した業種に１つでも該当する店舗を取り出す
        this.dispShopListAll.map((shopData) => {
          if (
            selectBrandRelSalonList.indexOf(Number(shopData.id)) !== -1 &&
            checkBrandShopList.indexOf(shopData) === -1
          ) {
            checkBrandShopList.push(shopData);
          }
        });
      }
      // 業種に該当する店舗のみの一覧に置き換える
      this.dispShopListAll = checkBrandShopList.sort((a, b) => a.id - b.id);
    }
    // ▼▼▼文字列チェック▼▼▼
    // 検索用の文字列が入力されている場合、文字列に該当する店舗のみのリストを作成する
    if (this.shopListSearchInputValue !== null && this.shopListSearchInputValue !== '') {
      let dispShopListWithSearchInputMatch = [];
      // 表示店舗リストの中から、文字列に一致する店舗のみ抽出する
      for (let i = 0; i < this.dispShopListAll.length; i++) {
        // 店舗略名、住所、アクセス、電話番号のいずれかのなかで検索文字列を含む場合、表示リストに加える
        if (
          this.dispShopListAll[i].preferenceData.shortName.match(this.shopListSearchInputValue) ||
          this.dispShopListAll[i].salonPageData.address.match(this.shopListSearchInputValue) ||
          this.dispShopListAll[i].salonPageData.access.match(this.shopListSearchInputValue) ||
          this.dispShopListAll[i].salonPageData.tel.match(this.shopListSearchInputValue)
        ) {
          dispShopListWithSearchInputMatch.push(this.dispShopListAll[i]);
        }
      }
      // 表示用リストを、抽出した店舗リストで更新する
      this.dispShopListAll = dispShopListWithSearchInputMatch;
    }

    // スクロール位置を最上部にする
    if (this.shopListIonContent) {
      this.shopListIonContent.scrollToTop();
    }
    // 表示店舗リストの内容で４つ表示
    this.appendItems(4);
  }

  public async clickShopListBooking(shopName) {
    const param: ParamForReservation = {
      staffId: -1,
      lastReservation: -1,
      salonName: shopName
    };
    this.myPageService.authenticateKey(MyPageService.RESERVATION, param);
  }

  // ==========================================================
  // 店舗マップ周り
  // ==========================================================
  setMap() {
    if (!this.apiLoaded) {
      return false;
    }
    var _this = this;
    var isFirstMarker = true;

    for (let i = 0; i < this.mapShopList.length; i++) {
      let shopData = this.mapShopList[i];

      // 先頭の店舗を初期表示位置でセットする
      if (isFirstMarker) {
        isFirstMarker = false;
        this.center = { lat: Number(shopData.salonPageData.lat), lng: Number(shopData.salonPageData.lon) };
        this.map.googleMap.setCenter(this.center);
      }
      // マーカー作成用のデータリストに追加する
      let marker = new ExtendedMarker({
        id: shopData.id,
        position: {
          lat: Number(shopData.salonPageData.lat),
          lng: Number(shopData.salonPageData.lon)
        },
        draggable: false,
        opacity: 1,
        animation: null,
        icon: {
          url: 'assets/images/icons/map/map_marker_orange.svg',
          scaledSize: new google.maps.Size(20, 32),
          anchor: new google.maps.Point(10, 32)
        }
      });
      this.markers.push(marker);
    }

    // 現在地の取得
    this.startWatchPosition();
  }

  /* 現在位置のwatch開始 */
  startWatchPosition() {
    if (!this.watchPositionId) {
      this.watchPositionId = 0;
    }
    if (window.navigator.geolocation && this.watchPositionId === 0) {
      return (this.watchPositionId = window.navigator.geolocation.watchPosition(
        this.onWatchPositionSucces.bind(this),
        this.onWatchPositionFailed,
        {
          enableHighAccuracy: false,
          timeout: 10000,
          maximumAge: 0
        }
      ));
    }
  }

  clearWatchPosition() {
    if (window.navigator.geolocation && this.watchPositionId > 0) {
      window.navigator.geolocation.clearWatch(this.watchPositionId);
      this.watchPositionId = 0;
    }
  }

  onWatchPositionSucces(position) {
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;
    this.locationLat = lat;
    this.locationLon = lng;
    this.locationRadius = position.coords.accuracy;
    // 現在地ボタンを更新する
    this.createCenterControl();
    if (!this.alreadyShowCurrent) {
      this.alreadyShowCurrent = true;
      // 現在地をセンターにする
      this.center = { lat: lat, lng: lng };
      this.map.googleMap.setCenter(this.center);
      // 近いサロンを選択状態にする
      this.focusByPosition(lat, lng);
    }
  }

  onWatchPositionFailed(error) {
    return null;
  }

  createCenterControl() {
    this.map.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].clear();
    const centerControlDiv = document.createElement('div');
    centerControlDiv.style.marginRight = '10px';

    const controlButton = document.createElement('button');
    controlButton.style.backgroundColor = '#fff';
    controlButton.style.border = '2px solid #fff';
    controlButton.style.borderRadius = '3px';
    controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlButton.style.margin = '8px 0 22px';
    controlButton.style.width = '40px';
    controlButton.style.height = '40px';
    controlButton.style.display = 'flex';
    controlButton.style.justifyContent = 'center';
    controlButton.style.alignItems = 'center';
    controlButton.type = 'button';
    controlButton.id = 'pac-current-position-button';

    const imgElement = document.createElement('img');
    imgElement.src = 'assets/images/icons/map/map_marker_current_position_button.svg';
    imgElement.style.width = '20px';
    imgElement.style.height = '20px';

    controlButton.appendChild(imgElement);

    controlButton.addEventListener('click', () => {
      this.center = { lat: this.locationLat, lng: this.locationLon };
      this.map.googleMap.setCenter(this.center);
    });

    centerControlDiv.appendChild(controlButton);
    this.map.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
  }

  /* 位置情報から一番近いサロンを選択（地図の移動処理は行わない） */
  focusByPosition(pLat, pLng) {
    var d, index, j, lat, len, lng, minDistance, minDistanceIndex, minDistanceSalon, ref, salon;
    minDistance = 0.5;
    minDistanceSalon = null;
    minDistanceIndex = -1;
    ref = this.mapShopList;
    for (index = j = 0, len = ref.length; j < len; index = ++j) {
      salon = ref[index];
      lat = Number(salon.salonPageData.lat);
      lng = Number(salon.salonPageData.lon);
      d = this.distance(lat, lng, pLat, pLng);
      if (d < minDistance) {
        minDistance = d;
        minDistanceSalon = salon;
        minDistanceIndex = index;
      }
    }
    if (minDistanceSalon) {
      console.log(minDistanceSalon);
      this.clickShopMarker(minDistanceSalon.id);
    }
  }

  // 距離計算（いますぐから流用。計算の詳細は不明）
  distance(lat1, lon1, lat2, lon2) {
    const p = 0.017453292519943295;
    const c = Math.cos;
    const a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    return 12742 * Math.asin(Math.sqrt(a));
  }

  // マーカータップ時のアニメーション変更処理
  clickShopMarker(markerId) {
    // マーカーのバウンド、マップのマーカーid（店舗id）にあわせてスライドする
    this.slideToFromShopId(markerId);
  }

  // マップ検索入力要素のエンターキーをkeydownしたときのイベント
  inputKeydownEnter(e) {
    // 変換確定時は229、通常エンターの場合は13が入る
    this.searchMapInputKeydownCode = e.which;
  }
  // マップ検索入力要素のエンターキーをkeyupしたときのイベント
  inputKeyupEnter(e, value) {
    // エンターキーが入力され、keydown時のキーコードと一致する場合
    if (e.which === 13 && e.which === this.searchMapInputKeydownCode) {
      // エンターキーによる検索を実行する
      this.searchMap(value);
    }
  }

  // マップで店舗検索
  searchMap(value) {
    var _this = this;
    // マップ検索テキスト
    var searchText = value;

    // 店舗名、住所を優先して検索する（アクセスなどで先にヒットする可能性があるので）
    for (let i = 1; i <= 2; i++) {
      for (let j = 0; j < this.shopListPageData.shopList.length; j++) {
        // 店舗名、住所が検索文字と一致した場合
        if (
          (i === 1 && this.shopListPageData.shopList[j].preferenceData.shortName.match(searchText)) ||
          (i === 1 && this.shopListPageData.shopList[j].salonPageData.address.match(searchText)) ||
          (i === 1 && this.shopListPageData.shopList[j].salonPageData.tel.match(searchText)) ||
          (i === 2 && this.shopListPageData.shopList[j].salonPageData.access.match(searchText))
        ) {
          console.log(this.shopListPageData.shopList[j]);
          // マップの表示位置と店舗スライドを一致する店舗のものに変更する
          this.mapTo(this.shopListPageData.shopList[j]);
          this.slideToFromShopId(this.shopListPageData.shopList[j].id);
          return;
        }
      }
    }

    // 店舗一覧から一致しなかった場合、地名検索で座標を取得する
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address: searchText
      },
      function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          // 成功時、マップの表示位置を取得した座標に変更する
          _this.center = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
          _this.map.googleMap.setCenter(_this.center);
        } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          alert('見つかりません');
        } else {
          alert('エラー発生');
        }
      }
    );
  }

  checkSlide(swiper) {
    const activeIndex = swiper.activeIndex; // アクティブなスライドのインデックス
    const lastIndex = swiper.slides.length - 1; // スライドの最後のインデックス
    let slides = swiper.slides;

    // 'slide'モードの場合、前へ、次へボタンの表示状態を変更
    if (this.slideMode === 'slide') {
      let prevBtn = swiper.navigate.prevEl;
      let nextBtn = swiper.navigate.nextEl;
      // 前へ、次へボタンの表示状態を変更
      if (activeIndex === 0) {
        // 最初のスライドの場合、前へボタンを非活性にする
        prevBtn.classList.add('swiper-button-disabled');
        nextBtn.classList.remove('swiper-button-disabled');
      } else if (activeIndex === lastIndex) {
        // 最後のスライドの場合、次へボタンを非活性にする
        prevBtn.classList.remove('swiper-button-disabled');
        nextBtn.classList.add('swiper-button-disabled');
      } else {
        // 途中のスライドの場合、前へ、次へボタンを活性にする
        prevBtn.classList.remove('swiper-button-disabled');
        nextBtn.classList.remove('swiper-button-disabled');
      }
    }

    // 変更後のスライドにあわせて、マーカーのバウンド、マップ表示位置変更
    var activeShopId = 0;
    if (typeof slides[activeIndex] !== 'undefined') {
      activeShopId = slides[activeIndex].dataset['shopId'];
      this.bounceMarker(activeShopId);
      this.mapTo(this.mapShopList.filter((shop) => shop.id === activeShopId)[0]);
    }
  }

  // ==========================================================
  // 店舗マップ、スライダー　アニメーション
  // ==========================================================
  // マーカーのバウンド
  bounceMarker(shopId) {
    // タップしたマーカーの店舗IDと、マーカーリストのIDが一致すればバウンド、一致しないマーカーはアニメーション停止
    this.markers.forEach((marker) => {
      if (shopId === marker.id) {
        marker.animation = google.maps.Animation.BOUNCE;
        marker.icon = {
          url: 'assets/images/icons/map/map_marker_pink.svg',
          scaledSize: new google.maps.Size(25, 40),
          anchor: new google.maps.Point(12.5, 40)
        };
      } else {
        marker.setAnimation(null);
        marker.icon = {
          url: 'assets/images/icons/map/map_marker_orange.svg',
          scaledSize: new google.maps.Size(20, 32),
          anchor: new google.maps.Point(10, 32)
        };
      }
    });
  }
  // マップの表示位置変更
  mapTo(shopData) {
    // クリックした店舗のマーカーがセンターにくるようにマップを移動する
    this.center = { lat: Number(shopData.salonPageData.lat), lng: Number(shopData.salonPageData.lon) };
    this.map.googleMap.panTo(this.center);
  }
  // 店舗idを元にスライド移動
  slideToFromShopId(shopId, speed: number = 400) {
    // スライダーの要素を取得
    let shopSwiper = this.mapShopSlider.nativeElement.children[0];
    // スライド一覧を取得
    let slides = shopSwiper.swiper.slides;
    // 複製スライドを除いた表示用のスライドを取得
    let activeSlides = shopSwiper.swiper.slides.filter((slide) => {
      return !slide.classList.contains('duplicateSlide');
    });
    // 表示用スライドの中からshopIdに一致するスライドを取得（変更後のスライド）
    const toShopSlide = activeSlides.filter((slide, num) => {
      if (slide.dataset['shopId'] === shopId) {
        return slide;
      }
    });
    // 一致するスライドがあれば、そのスライドに移動する
    if (toShopSlide.length !== 0) {
      shopSwiper.swiper.slideTo(slides.indexOf(toShopSlide[0]), speed);
    }
  }

  private async setTheme() {
    let themeData = await this.pds.getThemeData();
    this.shopListModalStyles = this.pds.setThemeForShopListMapModal(themeData);
  }

  private resetTheme() {
    this.pds.resetThemeForShopListMapModal();
  }

  // モーダル内をスクロールした際に、モーダル自体のスクロールを止める
  stopDragging(event: TouchEvent): void {
    event.stopPropagation();
  }
}

// 各マーカーの項目
interface ExtendedMarkerOptions extends google.maps.MarkerOptions {
  id: string;
}

class ExtendedMarker extends google.maps.Marker {
  constructor(options: ExtendedMarkerOptions) {
    super(options);
  }
}
