<ion-header
  id="modalHeader"
  class="modalHeader {{ parameter.openMode }} {{ parameter.shopDetail ? 'showShopDetail' : '' }}"
  (touchmove)="stopDragging($event)"
>
  <ion-toolbar class="modalHeader__toolbar">
    <!-- モーダルヘッダー 左側 -->
    <ion-buttons slot="start"></ion-buttons>
    <!-- モーダルヘッダー 右側 -->
    <ion-buttons slot="end">
      <ion-button class="close-btn" (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <!-- モーダルヘッダー タイトル部分（セグメント切り替え） -->
    <ion-title>
      <ion-segment
        class="list-map-segment"
        value="{{ listMapSegmentMode }}"
        (click)="changeListMap(listMapSegment.value)"
        #listMapSegment
      >
        <ion-segment-button class="list-map-segment-btn" value="map">
          <ion-label stacked>地図</ion-label>
        </ion-segment-button>
        <ion-segment-button class="list-map-segment-btn" value="list">
          <ion-label>一覧</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content
  class="modal-body __modalStyle__"
  [scrollY]="false"
  [ngClass]="shopListModalStyles"
  (touchmove)="stopDragging($event)"
>
  <div class="shopDetailModal__main">
    <!-- 地図 -->
    <main id="mainMap" class="mainMap" [class.show]="listMapSegmentMode == 'map'">
      <section class="mapContent__map sec">
        <div class="mapContent__map__searchArea">
          <ion-searchbar
            mode="ios"
            id="mapSearchInput"
            #mapSearchInput
            class="mapContent__map__searchArea__input"
            show-clear-button="never"
            placeholder="店名・電話・駅・住所"
            (keydown.enter)="inputKeydownEnter($event)"
            (keyup.enter)="inputKeyupEnter($event, mapSearchInput.value)"
          ></ion-searchbar>
          <ion-button
            id="mapSearchBtn"
            class="mapContent__map__searchArea__btn"
            (click)="searchMap(mapSearchInput.value)"
          >
            <ion-icon name="search-outline"></ion-icon>
          </ion-button>
        </div>
        <div *ngIf="apiLoaded" class="mapContent__map__mapWrapper">
          <google-map id="agmMap" #shopMap [options]="mapOptions">
            <!-- 位置情報が取得できた時の現在地マーカー -->
            <map-marker
              *ngIf="locationLat != null && locationLon != null"
              [position]="{ lat: locationLat, lng: locationLon }"
              [options]="{
                opacity: 1,
                draggable: false,
                icon: currentPositionIcon
              }"
            ></map-marker>
            <!-- 位置情報が取得できた時の現在地サークル -->
            <map-circle
              *ngIf="locationLat != null && locationLon != null"
              [center]="{ lat: locationLat, lng: locationLon }"
              [radius]="locationRadius"
              [options]="{
                clickable: true,
                strokeColor: 'white',
                strokeOpacity: 1.0,
                strokeWeight: 1,
                fillColor: 'aqua',
                fillOpacity: 0.3
              }"
            ></map-circle>
            <!-- 各店舗のマーカー -->
            <map-marker
              *ngFor="let marker of markers"
              #shopMarker
              [position]="marker.position"
              [options]="{
                opacity: marker.opacity,
                animation: marker.animation,
                draggable: marker.draggable,
                icon: marker.icon
              }"
              (mapClick)="clickShopMarker(marker.id)"
            ></map-marker>
          </google-map>
        </div>
      </section>
      <section
        #mapShopSlider
        class="mapContent__shopSlider sec"
        [class.lock]="slideMode == 'lock'"
        [class.slide]="slideMode == 'slide'"
        [class.loop]="slideMode == 'loop'"
      >
        <!-- ↑sectionのクラス付与文、全店今日明日ボタンを表示する際に追加してください [class.with-group-name-area]="companyData.isInstantReservationEnabled" -->
        <!-- <div class="group-name-area" *ngIf="companyData.isInstantReservationEnabled">
          <div class="group-name-text">
            {{ companyData.instantReservationGroupName }}
          </div>
          <a class="attention-color-button" innerHTML="{{ instantReservationButtonName }}"></a>
        </div> -->
        <swiper-container class="mapContent__shopSlider__swiperContainer">
          <!-- ↓↓↓ スライダー前複製 ↓↓↓ -->
          <ng-container *ngIf="slideMode === 'loop'">
            <swiper-slide
              class="mapContent__shopSlider__swiperSlide duplicateSlide"
              [attr.data-shop-id]="shopData.id"
              *ngFor="let shopData of mapShopListBeforeDup"
            >
              <div class="mapContent__shopSlider__card">
                <div
                  class="mapContent__shopSlider__card__body"
                  [class.onlyShopInfo]="shopData.isShopInfo && !shopData.isShopPhoto"
                >
                  <div
                    class="mapContent__shopSlider__card__body__visual__square"
                    *ngIf="shopData.preferenceData.isDisplayShopPhoto && shopData.preferenceData.shopPhoto != null"
                  >
                    <source
                      type="image/webp"
                      [attr.defaultImage]="loadingImage"
                      [attr.lazyLoad]="shopData.preferenceData.webpShopPhoto"
                    />
                    <img
                      [defaultImage]="loadingImage"
                      [lazyLoad]="shopData.preferenceData.shopPhoto"
                      class="mapContent__shopSlider__card__body__visual__img"
                      [alt]="
                        shopData.preferenceData.shopPhotoAlt
                          ? shopData.preferenceData.shopPhotoAlt
                          : shopData.preferenceData.shortName + 'の写真'
                      "
                    />
                  </div>
                  <div class="mapContent__shopSlider__card__body__info">
                    <div class="shop-header-area">
                      <div class="shop-header-content">
                        <div class="mapContent__shopSlider__card__body__info__brandName">
                          <ng-container *ngFor="let brand of brandList">
                            <div
                              *ngIf="brand.relSalons.includes(+shopData.id)"
                              class="mapContent__shopSlider__card__body__info__brandName__parts"
                            >
                              {{ brand.name }}
                            </div>
                          </ng-container>
                        </div>
                        <div
                          class="mapContent__shopSlider__card__body__info__shopName"
                          [class.noBrand]="shopData.brands.length == 0"
                        >
                          <div [innerHTML]="shopData.preferenceData.shortName"></div>
                        </div>
                      </div>
                      <div class="sub-action-button-area" *ngIf="isStandAlone && pds.viewMode !== 'browser'">
                        <button
                          #favorite
                          class="favorite"
                          (click)="changeFavorite(shopData.id, favoriteShopList.indexOf(shopData.id) != -1)"
                        >
                          <ion-icon
                            name="star-outline"
                            class="favorite_off"
                            *ngIf="favoriteShopList.indexOf(shopData.id) == -1"
                          ></ion-icon>
                          <ion-icon
                            name="star"
                            class="favorite_on"
                            *ngIf="favoriteShopList.indexOf(shopData.id) != -1"
                          ></ion-icon>
                        </button>
                      </div>
                    </div>
                    <div class="mapContent__shopSlider__card__body__info__textArea">
                      <div
                        class="mapContent__shopSlider__card__body__info__textContent"
                        *ngIf="
                          shopData.salonPageData.businessHours && shopData.salonPageData.businessHours.trim().length > 0
                        "
                      >
                        <ion-icon
                          name="time-outline"
                          class="mapContent__shopSlider__card__body__info__textContent__icon"
                        ></ion-icon>
                        <div
                          [innerHTML]="shopData.salonPageData.businessHours"
                          class="mapContent__shopSlider__card__body__info__textContent__body"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="footer-button-area">
                  <span
                    class="mapContent__shopSlider__card__reserveBtn functional-color-button"
                    (click)="openShopDetailModal(shopData.accountName, 'home')"
                    >店舗詳細</span
                  >
                  <span
                    class="mapContent__shopSlider__card__reserveBtn attention-color-button"
                    (click)="clickShopListBooking(shopData.accountName)"
                    >予約</span
                  >
                </div>
              </div>
            </swiper-slide>
          </ng-container>
          <!-- ↑↑↑ スライダー前複製 ↑↑↑ -->
          <!-- ↓↓↓ スライダー本体 ↓↓↓ -->
          <swiper-slide
            class="mapContent__shopSlider__swiperSlide"
            [attr.data-shop-id]="shopData.id"
            *ngFor="let shopData of mapShopList"
          >
            <div class="mapContent__shopSlider__card">
              <div
                class="mapContent__shopSlider__card__body"
                [class.onlyShopInfo]="shopData.isShopInfo && !shopData.isShopPhoto"
              >
                <div
                  class="mapContent__shopSlider__card__body__visual__square"
                  *ngIf="shopData.preferenceData.isDisplayShopPhoto && shopData.preferenceData.shopPhoto != null"
                >
                  <source
                    type="image/webp"
                    [attr.defaultImage]="loadingImage"
                    [attr.lazyLoad]="shopData.preferenceData.webpShopPhoto"
                  />
                  <img
                    [defaultImage]="loadingImage"
                    [lazyLoad]="shopData.preferenceData.shopPhoto"
                    class="mapContent__shopSlider__card__body__visual__img"
                    [alt]="
                      shopData.preferenceData.shopPhotoAlt
                        ? shopData.preferenceData.shopPhotoAlt
                        : shopData.preferenceData.shortName + 'の写真'
                    "
                  />
                </div>
                <div class="mapContent__shopSlider__card__body__info">
                  <div class="shop-header-area">
                    <div class="shop-header-content">
                      <div class="mapContent__shopSlider__card__body__info__brandName">
                        <ng-container *ngFor="let brand of brandList">
                          <div
                            *ngIf="brand.relSalons.includes(+shopData.id)"
                            class="mapContent__shopSlider__card__body__info__brandName__parts"
                          >
                            {{ brand.name }}
                          </div>
                        </ng-container>
                      </div>
                      <div
                        class="mapContent__shopSlider__card__body__info__shopName"
                        [class.noBrand]="shopData.brands.length == 0"
                      >
                        <div [innerHTML]="shopData.preferenceData.shortName"></div>
                      </div>
                    </div>
                    <div class="sub-action-button-area" *ngIf="isStandAlone && pds.viewMode !== 'browser'">
                      <button
                        #favorite
                        class="favorite"
                        (click)="changeFavorite(shopData.id, favoriteShopList.indexOf(shopData.id) != -1)"
                      >
                        <ion-icon
                          name="star-outline"
                          class="favorite_off"
                          *ngIf="favoriteShopList.indexOf(shopData.id) == -1"
                        ></ion-icon>
                        <ion-icon
                          name="star"
                          class="favorite_on"
                          *ngIf="favoriteShopList.indexOf(shopData.id) != -1"
                        ></ion-icon>
                      </button>
                    </div>
                  </div>
                  <div class="mapContent__shopSlider__card__body__info__textArea">
                    <div
                      class="mapContent__shopSlider__card__body__info__textContent"
                      *ngIf="
                        shopData.salonPageData.businessHours && shopData.salonPageData.businessHours.trim().length > 0
                      "
                    >
                      <ion-icon
                        name="time-outline"
                        class="mapContent__shopSlider__card__body__info__textContent__icon"
                      ></ion-icon>
                      <div
                        [innerHTML]="shopData.salonPageData.businessHours"
                        class="mapContent__shopSlider__card__body__info__textContent__body"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer-button-area">
                <span
                  class="mapContent__shopSlider__card__reserveBtn functional-color-button"
                  (click)="openShopDetailModal(shopData.accountName, 'home')"
                  >店舗詳細</span
                >
                <span
                  class="mapContent__shopSlider__card__reserveBtn attention-color-button"
                  (click)="clickShopListBooking(shopData.accountName)"
                  >予約</span
                >
              </div>
            </div>
          </swiper-slide>
          <!-- ↑↑↑ スライダー本体 ↑↑↑ -->
          <!-- ↓↓↓ スライダー後複製 ↓↓↓ -->
          <ng-container *ngIf="slideMode === 'loop'">
            <swiper-slide
              class="mapContent__shopSlider__swiperSlide duplicateSlide"
              [attr.data-shop-id]="shopData.id"
              *ngFor="let shopData of mapShopListAfterDup"
            >
              <div class="mapContent__shopSlider__card">
                <div
                  class="mapContent__shopSlider__card__body"
                  [class.onlyShopInfo]="shopData.isShopInfo && !shopData.isShopPhoto"
                >
                  <div
                    class="mapContent__shopSlider__card__body__visual__square"
                    *ngIf="shopData.preferenceData.isDisplayShopPhoto && shopData.preferenceData.shopPhoto != null"
                  >
                    <source
                      type="image/webp"
                      [attr.defaultImage]="loadingImage"
                      [attr.lazyLoad]="shopData.preferenceData.webpShopPhoto"
                    />
                    <img
                      [defaultImage]="loadingImage"
                      [lazyLoad]="shopData.preferenceData.shopPhoto"
                      class="mapContent__shopSlider__card__body__visual__img"
                      [alt]="
                        shopData.preferenceData.shopPhotoAlt
                          ? shopData.preferenceData.shopPhotoAlt
                          : shopData.preferenceData.shortName + 'の写真'
                      "
                    />
                  </div>
                  <div class="mapContent__shopSlider__card__body__info">
                    <div class="shop-header-area">
                      <div class="shop-header-content">
                        <div class="mapContent__shopSlider__card__body__info__brandName">
                          <ng-container *ngFor="let brand of brandList">
                            <div
                              *ngIf="brand.relSalons.includes(+shopData.id)"
                              class="mapContent__shopSlider__card__body__info__brandName__parts"
                            >
                              {{ brand.name }}
                            </div>
                          </ng-container>
                        </div>
                        <div
                          class="mapContent__shopSlider__card__body__info__shopName"
                          [class.noBrand]="shopData.brands.length == 0"
                        >
                          <div [innerHTML]="shopData.preferenceData.shortName"></div>
                        </div>
                      </div>
                      <div class="sub-action-button-area" *ngIf="isStandAlone && pds.viewMode !== 'browser'">
                        <button
                          #favorite
                          class="favorite"
                          (click)="changeFavorite(shopData.id, favoriteShopList.indexOf(shopData.id) != -1)"
                        >
                          <ion-icon
                            name="star-outline"
                            class="favorite_off"
                            *ngIf="favoriteShopList.indexOf(shopData.id) == -1"
                          ></ion-icon>
                          <ion-icon
                            name="star"
                            class="favorite_on"
                            *ngIf="favoriteShopList.indexOf(shopData.id) != -1"
                          ></ion-icon>
                        </button>
                      </div>
                    </div>
                    <div class="mapContent__shopSlider__card__body__info__textArea">
                      <div
                        class="mapContent__shopSlider__card__body__info__textContent"
                        *ngIf="
                          shopData.salonPageData.businessHours && shopData.salonPageData.businessHours.trim().length > 0
                        "
                      >
                        <ion-icon
                          name="time-outline"
                          class="mapContent__shopSlider__card__body__info__textContent__icon"
                        ></ion-icon>
                        <div
                          [innerHTML]="shopData.salonPageData.businessHours"
                          class="mapContent__shopSlider__card__body__info__textContent__body"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="footer-button-area">
                  <span
                    class="mapContent__shopSlider__card__reserveBtn functional-color-button"
                    (click)="openShopDetailModal(shopData.accountName, 'home')"
                    >店舗詳細</span
                  >
                  <span
                    class="mapContent__shopSlider__card__reserveBtn attention-color-button"
                    (click)="clickShopListBooking(shopData.accountName)"
                    >予約</span
                  >
                </div>
              </div>
            </swiper-slide>
          </ng-container>
          <!-- ↑↑↑ スライダー後複製 ↑↑↑ -->
        </swiper-container>
        <div class="prevBtn swiper-button-prev">
          <ion-icon name="chevron-back"></ion-icon>
        </div>
        <div class="nextBtn swiper-button-next">
          <ion-icon name="chevron-forward"></ion-icon>
        </div>
      </section>
    </main>
    <!-- 地図ここまで -->

    <!-- 店舗一覧 -->
    <main
      class="mainShopList search"
      [class.selectBrand]="brandList.length > 0"
      [class.show]="listMapSegmentMode == 'list'"
    >
      <!-- 店舗詳細コンテンツ　サロン情報 -->
      <section class="shopListHeading sec">
        <div class="shopListHeading-inputConditions">
          <ion-searchbar
            mode="ios"
            #shopListSearchInput
            class="shopListHeading-inputConditions-input shopListHeading-searchInput"
            (ionInput)="inputSearch(shopListSearchInput.value)"
            placeholder="店名・電話・駅・住所"
          ></ion-searchbar>
        </div>
        <div
          class="shopListHeading-selectConditions"
          *ngIf="(isStandAlone && pds.viewMode !== 'browser') || brandList.length > 0"
        >
          <div class="shopListHeading-selectConditions-favorite" *ngIf="isStandAlone && pds.viewMode !== 'browser'">
            <button
              #changeFavoriteBtn
              class="filter-button"
              (click)="changeFavoriteList(changeFavoriteBtn)"
              [class.favorite-only]="isFavoriteOnly"
            >
              <ion-icon name="checkmark-sharp" class="checkmarkIcon" *ngIf="isFavoriteOnly"></ion-icon>
              <div>お気に入り</div>
              <ion-icon name="star" class="starIcon"></ion-icon>
            </button>
          </div>
          <ng-container *ngFor="let brandData of brandList">
            <button
              #selectBrandContent
              class="filter-button"
              (click)="selectBrand(selectBrandContent, brandData.id)"
              [class.selected]="selectBrandList.indexOf(brandData.id) != -1"
            >
              <ion-icon
                name="checkmark-sharp"
                class="checkmarkIcon"
                *ngIf="selectBrandList.indexOf(brandData.id) != -1"
              ></ion-icon>
              <div>{{ brandData.name }}</div>
            </button>
          </ng-container>
        </div>
      </section>

      <!-- 店舗詳細コンテンツ　サロン情報 -->
      <section id="shopListContent" class="shopListContent sec">
        <ion-content #shopListIonContent *ngIf="isShowShopList">
          <!-- <div class="group-name-area" *ngIf="companyData.isInstantReservationEnabled">
            <div class="group-name-text">
              {{ companyData.instantReservationGroupName }}
            </div>
            <a class="attention-color-button" innerHTML="{{ instantReservationButtonName }}"></a>
          </div> -->
          <ul id="shopList" class="shopListContent__container">
            <ng-container *ngFor="let shopData of dispShopList">
              <li id="shopContent_{{ shopData.id }}" class="shopListContent__shopContent">
                <div id="searchText_{{ shopData.id }}" class="shopListContent__shopContent__searchText">
                  {{ shopData.searchText }}
                </div>
                <div
                  #shopBody
                  class="shopListContent__shopContent__body"
                  [class.infoOpen]="shopBody.isInfoOpen"
                  [class.onlyShopPhoto]="!shopData.isShopInfo && shopData.isShopPhoto"
                  [class.onlyShopInfo]="shopData.isShopInfo && !shopData.isShopPhoto"
                >
                  <div
                    id="visualGroup_{{ shopData.id }}"
                    #shopBodyVisual
                    class="shopListContent__shopContent__body__visual__square"
                    *ngIf="shopData.preferenceData.isDisplayShopPhoto && shopData.preferenceData.shopPhoto != null"
                  >
                    <source
                      type="image/webp"
                      [attr.defaultImage]="loadingImage"
                      [attr.lazyLoad]="shopData.preferenceData.webpShopPhoto"
                    />
                    <img
                      [defaultImage]="loadingImage"
                      [lazyLoad]="shopData.preferenceData.shopPhoto"
                      class="shopListContent__shopContent__body__visual__img"
                      [alt]="
                        shopData.preferenceData.shopPhotoAlt
                          ? shopData.preferenceData.shopPhotoAlt
                          : shopData.preferenceData.shortName + 'の写真'
                      "
                    />
                  </div>
                  <!-- ここまでが画像 -->
                  <div class="shopListContent__shopContent__body__info" #shopBodyInfo>
                    <div class="sub-action-button-area" *ngIf="isStandAlone && pds.viewMode !== 'browser'">
                      <button
                        #favorite
                        class="favorite"
                        (click)="changeFavorite(shopData.id, favoriteShopList.indexOf(shopData.id) != -1)"
                      >
                        <ion-icon
                          name="star-outline"
                          class="favorite_off"
                          *ngIf="favoriteShopList.indexOf(shopData.id) == -1"
                        ></ion-icon>
                        <ion-icon
                          name="star"
                          class="favorite_on"
                          *ngIf="favoriteShopList.indexOf(shopData.id) != -1"
                        ></ion-icon>
                      </button>
                    </div>
                    <div class="shopListContent__shopContent__body__info__brandName">
                      <ng-container *ngFor="let brand of brandList">
                        <div
                          *ngIf="brand.relSalons.includes(+shopData.id)"
                          class="shopListContent__shopContent__body__info__brandName__parts"
                        >
                          {{ brand.name }}
                        </div>
                      </ng-container>
                    </div>
                    <div class="shopListContent__shopContent__shopName" [class.noBrand]="shopData.brands.length == 0">
                      {{ shopData.preferenceData.shortName }}
                    </div>
                  </div>
                </div>
                <!-- 説明テキスト -->
                <div id="textArea_{{ shopData.id }}" class="shopListContent__shopContent__body__info__textArea">
                  <div
                    class="shopListContent__shopContent__body__info__textContent"
                    *ngIf="shopData.salonPageData.address && shopData.salonPageData.address.trim().length > 0"
                  >
                    <ion-icon
                      name="location-outline"
                      class="shopListContent__shopContent__body__info__textContent__icon"
                    ></ion-icon>
                    <div class="shopListContent__shopContent__body__info__textContent__body">
                      {{ shopData.salonPageData.address }}
                    </div>
                  </div>
                  <div
                    class="shopListContent__shopContent__body__info__textContent"
                    *ngIf="
                      shopData.salonPageData.businessHours && shopData.salonPageData.businessHours.trim().length > 0
                    "
                  >
                    <ion-icon
                      name="time-outline"
                      class="shopListContent__shopContent__body__info__textContent__icon"
                    ></ion-icon>
                    <div class="shopListContent__shopContent__body__info__textContent__body">
                      {{ shopData.salonPageData.businessHours }}
                    </div>
                  </div>
                  <div
                    class="shopListContent__shopContent__body__info__textContent"
                    *ngIf="shopData.salonPageData.holiday && shopData.salonPageData.holiday.trim().length > 0"
                  >
                    <ion-icon
                      src="assets/images/icons/shopList/holiday.svg"
                      class="shopListContent__shopContent__body__info__textContent__icon"
                    ></ion-icon>
                    <div class="shopListContent__shopContent__body__info__textContent__body">
                      {{ shopData.salonPageData.holiday }}
                    </div>
                  </div>
                  <div
                    class="shopListContent__shopContent__body__info__textContent"
                    *ngIf="shopData.salonPageData.access && shopData.salonPageData.access.trim().length > 0"
                  >
                    <ion-icon
                      src="assets/images/icons/shopList/access-arrow.svg"
                      class="shopListContent__shopContent__body__info__textContent__icon"
                    >
                    </ion-icon>
                    <div class="shopListContent__shopContent__body__info__textContent__body">
                      {{ shopData.salonPageData.access }}
                    </div>
                  </div>
                </div>
                <div class="footer-button-area">
                  <span
                    class="mapContent__shopSlider__card__reserveBtn functional-color-button"
                    (click)="openShopDetailModal(shopData.accountName, 'home')"
                    >店舗詳細</span
                  >
                  <span
                    class="shopListContent__shopContent__reserveBtn attention-color-button"
                    (click)="clickShopListBooking(shopData.accountName)"
                    >予約</span
                  >
                </div>
              </li>
            </ng-container>
          </ul>
          <ion-infinite-scroll
            id="infinite-scroll"
            threshold="15%"
            (ionInfinite)="infiniteLoadData($event)"
            [disabled]="isInfiniteScrollDisabled"
          >
            <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-content>
      </section>
      <!-- /.shopListContent -->
    </main>
    <!-- 店舗一覧ここまで -->
  </div>
</ion-content>
