<div *ngIf="showInstallMessageforiPad" class="mobileAction_balloonForiPad" id="mobileAction_balloonForiOS">
  <span class="mobileAction_balloonForiPad_text bold"
    ><img
      class="mobileAction_balloonForiPad_A2HS"
      src="./../../assets/images/icons/icon_A2HS.png"
      alt="icon_A2HS"
    />を押して「ホーム画面に追加」することで<br />アプリとしてご利用いただけます。<br /><span
      class="mobileAction_balloonForiPad_text"
      >アプリでいつでも簡単に予約内容の確認・変更、メッセージの確認ができます。</span
    ><span class="mobileAction_balloonForiPad_okButton" (click)="closeInstallMessage()">閉じる</span></span
  >
</div>
<!-- ヘッダー -->
<header
  id="header"
  name="header"
  class="header"
  [ngClass]="headerStyle"
  [class.background_none]="headerBackgroundNone"
  [class.__modalStyle__]="isShopDetailNav"
  #header
>
  <!-- 例：ヘッダー帯あり (MV上) ＆ ロゴをヘッダー内中心 -->
  <!-- <header id="header" class="header noHeightTranslucent centerLogo">...</header> -->

  <!-- 例：ロゴをヘッダー内中心 -->
  <!-- <header id="header" class="header centerLogo">...</header> -->

  <!-- 例：ロゴをヘッダー内から消す＆MV内下部左寄せ -->
  <!-- <header id="header" class="header noLogo noLogo__bottomLeft">...</header> -->
  <div class="header_container">
    <!-- ロゴ（正方形・縦長）→削除 -->

    <!-- ロゴ（横長） -->
    <div class="header_logo_group_land">
      <span
        id="header_logo"
        name="header_logo"
        class="header_logo_land"
        [ngClass]="isDisplayLogo"
        *ngIf="isShowMenu && !hideLogo"
        (click)="clickLink('home')"
      >
        <img
          class="header_logo_land_img_land"
          [defaultImage]="loadingImage"
          [lazyLoad]="layoutData.logoImageLand"
          alt="logo"
        />
      </span>
      <img
        *ngIf="!isShowMenu"
        class="header_logo_land_img_land"
        [defaultImage]="loadingImage"
        [lazyLoad]="layoutData.logoImageLand"
        alt="logo"
      />
    </div>

    <!-- マイページアイコン -->
    <div
      class="header_myPageIcon"
      (click)="openMyPage()"
      *ngIf="isInStandaloneMode && pds.viewMode !== 'browser' && isShowMenu && !hideMyPageIcon"
      [class.__modalStyle__]="isShopDetailNav"
    >
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 28 28"
        enable-background="new 0 0 28 28"
        xml:space="preserve"
      >
        <g class="header_myPageIcon_svg">
          <path
            d="M14,0C6.268,0,0,6.268,0,14s6.268,14,14,14s14-6.268,14-14S21.732,0,14,0z M14,2c6.617,0,12,5.383,12,12
  c0,2.546-0.803,4.905-2.161,6.849c-1.063-3.029-3.486-5.392-6.489-6.421c1.447-1.044,2.394-2.74,2.394-4.657
  c0-3.167-2.577-5.744-5.745-5.744S8.255,6.604,8.255,9.772c0,1.917,0.947,3.613,2.394,4.657c-3.004,1.029-5.426,3.391-6.489,6.421
  C2.803,18.905,2,16.546,2,14C2,7.383,7.383,2,14,2z M14,13.517c-2.065,0-3.745-1.68-3.745-3.745c0-2.064,1.68-3.744,3.745-3.744
  s3.745,1.68,3.745,3.744C17.745,11.837,16.065,13.517,14,13.517z M5.734,22.677c0.761-3.915,4.23-6.816,8.266-6.816
  s7.505,2.901,8.266,6.816C20.111,24.73,17.204,26,14,26S7.889,24.73,5.734,22.677z"
          />
        </g>
        <g class="header_myPageIcon_svg_active">
          <path
            d="M10.645,14.426c-1.444-1.044-2.39-2.739-2.39-4.653c0-3.167,2.577-5.745,5.745-5.745s5.745,2.577,5.745,5.745
              c0,1.915-0.945,3.609-2.39,4.653c3.827,1.306,6.661,4.771,7.03,8.94C26.625,20.883,28,17.606,28,14c0-7.732-6.268-14-14-14
              S0,6.268,0,14c0,3.606,1.375,6.883,3.615,9.365C3.984,19.197,6.818,15.731,10.645,14.426z"
          />
          <path
            d="M14,15.862c-4.647,0-8.429,3.78-8.429,8.428c0,0.307,0.019,0.61,0.051,0.908C7.96,26.95,10.854,28,14,28
              c3.146,0,6.04-1.05,8.377-2.802c0.032-0.299,0.051-0.601,0.051-0.908C22.428,19.642,18.647,15.862,14,15.862z"
          />
          <circle cx="14" cy="9.772" r="3.745" />
        </g>
      </svg>
    </div>

    <!-- 不可視input。メニュー開閉操作用。 -->
    <!--       <input id="header_checkbox" class="header_checkbox" type="checkbox">
 -->
    <!-- ハンバーガーメニュー -->
    <label
      *ngIf="isShowMenu && !hideMenuIcon"
      (click)="openNav()"
      name="header_menuIcon"
      class="header_menuIcon"
      for="header_checkbox"
      [class.__modalStyle__]="isShopDetailNav"
      ><span></span
    ></label>

    <!-- モバイルメニュー展開時背景 -->
    <label id="header_openBg" class="header_openBg" for="header_checkbox"></label>

    <div
      *ngIf="isShowMenu"
      name="header_content"
      class="header_content"
      id="display_part"
      [class.active]="showHeaderContent"
      [class.__modalStyle__]="isShopDetailNav"
    >
      <!-- モバイルメニュー展開時クローズボタン -->
      <label
        (click)="closeNav()"
        id="header_content__closeBtn"
        class="header_content__closeBtn"
        for="header_checkbox"
        [class.__modalStyle__]="isShopDetailNav"
        ><span></span
      ></label>

      <div class="header_content_nav">
        <div
          id="headerContentNavList_{{ layoutData.id }}"
          class="header_content_nav__list scrollable"
          #headerContentNavList
        >
          <ng-container *ngFor="let m of layoutData.menus">
            <ng-container *ngIf="m.isDisplayCheckAppEnabled">
              <div class="header_content_nav__item scrollable" id="item_{{ m.id }}">
                <span
                  (click)="clickChildMenu(m)"
                  class="header_content_nav__link jpfont scrollable"
                  tabindex="-1"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  {{ m.title }}
                  <div
                    class="header_content_nav__link__childMenuIcon scrollable"
                    [class.__modalStyle__]="isShopDetailNav"
                    id="childMenuIcon_{{ m.id }}"
                    [class.open]="m.isChildMenuOpen"
                    *ngIf="m.childMenus.length > 0"
                  ></div>
                </span>
                <ul
                  class="header_content_nav__childList childMenuGroup"
                  id="childMenus_{{ m.id }}"
                  *ngIf="m.childMenus.length > 0 && m.isChildMenuOpen"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  <li class="header_content_nav__childItem">
                    <span
                      class="header_content_nav__childLink jpfont"
                      (click)="clickLink(m.name, { pageName: m.name })"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{ m.title }}
                    </span>
                  </li>
                  <li class="header_content_nav__childItem" *ngFor="let cm of m.childMenus">
                    <span
                      class="header_content_nav__childLink jpfont"
                      (click)="clickChildListMenu(m.name, cm.name)"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{ cm.title }}
                    </span>
                  </li>
                </ul>
                <ul
                  class="header_content_nav__hamburgerMenu__childList scrollable"
                  id="hamburgerChildMenus_{{ m.id }}"
                  [class.__modalStyle__]="isShopDetailNav"
                  *ngIf="m.isChildMenuOpen && m.childMenus.length > 0"
                >
                  <li class="header_content_nav__hamburgerMenu__childItem scrollable">
                    <span
                      class="header_content_nav__hamburgerMenu__childLink jpfont scrollable"
                      (click)="clickLink(m.name)"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{ m.title }}
                    </span>
                  </li>
                  <li class="header_content_nav__hamburgerMenu__childItem scrollable" *ngFor="let cm of m.childMenus">
                    <span
                      class="header_content_nav__hamburgerMenu__childLink jpfont scrollable"
                      (click)="clickChildListMenu(m.name, cm.name)"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{ cm.title }}
                    </span>
                  </li>
                </ul>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="header_content_nav__item header_content_nav__item__otherBtn" *ngIf="otherMenuList.length > 0">
          <span
            (click)="toggleOtherMenu()"
            class="header_content_nav__link btn_otherHeader"
            tabindex="-1"
            [class.__modalStyle__]="isShopDetailNav"
          ></span>
          <ul
            id="otherList"
            class="header_content_nav__otherList childMenuGroup"
            [class.__modalStyle__]="isShopDetailNav"
            *ngIf="isOtherListOpen"
          >
            <ng-container *ngFor="let m of otherMenuList">
              <li class="header_content_nav__otherItem" id="otherMenu_{{ m.id }}">
                <span
                  (click)="clickOtherChildMenu(m)"
                  class="header_content_nav__otherLink jpfont"
                  [class.__modalStyle__]="isShopDetailNav"
                >
                  {{ m.title }}
                  <div
                    class="header_content_nav__otherLink__childMenuIcon jpfont"
                    id="otherChildMenuIcon_{{ m.id }}"
                    [class.__modalStyle__]="isShopDetailNav"
                    *ngIf="m.childMenus.length > 0"
                    [class.open]="m.isOtherChildMenuOpen"
                  ></div>
                </span>
                <ul
                  class="header_content_nav__otherChildList"
                  id="otherChildMenu_{{ m.id }}"
                  [class.__modalStyle__]="isShopDetailNav"
                  *ngIf="m.isOtherChildMenuOpen && m.childMenus.length > 0"
                >
                  <li class="header_content_nav__otherChildItem" [class.__modalStyle__]="isShopDetailNav">
                    <span
                      class="header_content_nav__otherChildLink jpfont"
                      (click)="clickLink(m.name, { pageName: m.name })"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{ m.title }}
                    </span>
                  </li>
                  <li
                    class="header_content_nav__otherChildItem"
                    [class.__modalStyle__]="isShopDetailNav"
                    *ngFor="let cm of m.childMenus"
                  >
                    <span
                      class="header_content_nav__otherChildLink jpfont"
                      (click)="clickChildListMenu(m.name, cm.name)"
                      [class.__modalStyle__]="isShopDetailNav"
                    >
                      {{ cm.title }}
                    </span>
                  </li>
                </ul>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="header_content_nav__item header_content_nav__item__shopListBtn" *ngIf="showShopListMobileBtn">
          <span (click)="openShopListMapModal()" class="header_nav__link btn_shopListMapHeader">店舗一覧</span>
        </div>
        <div
          class="header_content_nav__item header_content_nav__item__bookBtn"
          [class.shop-list-map-padding]="showShopListMobileBtn"
        >
          <span
            (click)="closeNavLinkClick(); clickBooking()"
            target="_blank"
            class="header_nav__link btn_bookHeader"
            [class.__modalStyle__]="isShopDetailNav"
            >予約する</span
          >
        </div>
      </div>
    </div>

    <div
      name="header_content_myPage"
      class="header_content myPage"
      [class.__modalStyle__]="isShopDetailNav"
      [class.active]="showHeaderContentMyPage"
      *ngIf="isInStandaloneMode && pds.viewMode !== 'browser' && isShowMenu"
    >
      <!-- モバイルメニュー展開時クローズボタン -->
      <label
        (click)="closeMyPage()"
        id="header_content__closeBtn"
        class="header_content__closeBtn"
        [class.__modalStyle__]="isShopDetailNav"
        for="header_checkbox"
        ><span></span
      ></label>
      <nav class="header_content_nav">
        <ul class="header_content_nav__list noMargin" [class.active]="showHeaderContentMyPageList">
          <li
            class="header_content_nav__item header_content_nav__item__logo"
            [class.__modalStyle__]="isShopDetailNav"
          ></li>
          <li *ngIf="this.layoutData.isPointSystem" class="header_content_nav__item">
            <span
              class="header_content_nav__link nohover enfont"
              [class.__modalStyle__]="isShopDetailNav"
              (click)="clickPoint()"
              >ポイント</span
            >
          </li>
          <li class="header_content_nav__item">
            <span
              class="header_content_nav__link nohover enfont"
              [class.__modalStyle__]="isShopDetailNav"
              (click)="clickReservationHistory()"
              >予約履歴</span
            >
          </li>
          <li class="header_content_nav__item">
            <span
              class="header_content_nav__link nohover enfont"
              [class.__modalStyle__]="isShopDetailNav"
              (click)="clickShareImageList()"
              >共有画像</span
            >
          </li>
          <li class="header_content_nav__item">
            <span
              class="header_content_nav__link nohover enfont"
              [class.__modalStyle__]="isShopDetailNav"
              (click)="clickMessage()"
              >メッセージ</span
            >
          </li>
          <li class="header_content_nav__item">
            <span
              class="header_content_nav__link nohover enfont"
              [class.__modalStyle__]="isShopDetailNav"
              (click)="clickHandShake()"
              >ハンドシェイク</span
            >
          </li>
          <!--
          <li class="header_content_nav__item">
            <a class="header_content_nav__link enfont">共有画像</a>
          </li>
          -->
          <!-- <li class="header_content_nav__item">
            <a class="header_content_nav__link enfont" (click)="openAppConf()" *ngIf="isAppConf">アプリ設定</a>
          </li> -->
          <li class="header_content_nav__item">
            <span
              class="header_content_nav__link enfont"
              [class.__modalStyle__]="isShopDetailNav"
              (click)="clickNotificationSetting()"
              >通知設定</span
            >
          </li>
          <li class="header_content_nav__item">
            <span class="header_content_nav__link enfont" [class.__modalStyle__]="isShopDetailNav" (click)="shareURL()"
              >友だちに紹介</span
            >
          </li>
          <li class="header_content_nav__item">
            <span
              class="header_content_nav__link enfont"
              [class.__modalStyle__]="isShopDetailNav"
              (click)="openAboutApp()"
              >アプリについて</span
            >
          </li>
          <li class="header_content_nav__item" *ngIf="pds.pwaEnv == 'staging'">
            <a class="header_content_nav__link enfont" (click)="openLineNotificationModal()"
              >「通知」設定のお願いを表示する</a
            >
          </li>
          <!--
          <li class="header_content_nav__item">
            <a class="header_content_nav__link enfont" [class.__modalStyle__]="isShopDetailNav" (click)="clickDeleteAccount()">アカウント削除</a>
          </li> -->
        </ul>

        <div class="header_content_appConf" [class.active]="showAppConf">
          <!-- モバイルメニュー展開時クローズボタン -->
          <label (click)="closeMyPageAndAppConf()" id="header_content__closeBtn" class="header_content__closeBtn"
            ><span class="black"></span
          ></label>
          <div class="header_content_appConf_header">
            <span class="header_content_appConf_header_back_btn" (click)="backMyPage()"><</span>
            <span class="header_content_appConf_header_text">アプリ設定</span>
          </div>
          <div class="header_content_appConf_accountCoop_title">
            <span class="header_content_appConf_accountCoop_title_text">アカウント連携</span>
          </div>
          <div class="header_content_appConf_accountCoop_body" (click)="openAccountCoop()">
            <span class="header_content_appConf_accountCoop_body_text"
              >他サイト（Google、Facebook）に登録している情報と、このアプリを連携することにより、他サイトのアカウントを使ってログインしていただくことができます。</span
            >
            <div class="header_content_appConf_accountCoop_body_next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="header_content_appConf_accountCoop_body_next_btn"
              >
                <path
                  d="M464,256c0-114.87-93.13-208-208-208S48,141.13,48,256s93.13,208,208,208S464,370.87,464,256ZM251.35,347.36a16,16,0,0,1-.09-22.63L303.58,272H170a16,16,0,0,1,0-32H303.58l-52.32-52.73A16,16,0,1,1,274,164.73l79.39,80a16,16,0,0,1,0,22.54l-79.39,80A16,16,0,0,1,251.35,347.36Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="header_content_accountCoop_filter" *ngIf="showAccountCoopFilter"></div>
        <div class="header_content_accountCoop" *ngIf="showAccountCoop" (click)="judgementAccountCoopRegion($event)">
          <div class="header_content_accountCoop_container accountCoop_contents">
            <div class="header_content_accountCoop_close accountCoop_contents">
              <svg
                class="header_content_accountCoop_close_btn"
                (click)="judgementAccountCoopRegion($event)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <line
                  x1="368"
                  y1="368"
                  x2="144"
                  y2="144"
                  style="fill: black; stroke: black; stroke-linecap: round; stroke-linejoin: round; stroke-width: 32px"
                />
                <line
                  x1="368"
                  y1="144"
                  x2="144"
                  y2="368"
                  style="fill: black; stroke: black; stroke-linecap: round; stroke-linejoin: round; stroke-width: 32px"
                />
              </svg>
            </div>
            <div class="header_content_accountCoop_title accountCoop_contents">
              <span class="header_content_accountCoop_title_text accountCoop_contents">アカウント連携</span>
            </div>
            <div class="header_content_accountCoop_body accountCoop_contents">
              <div class="header_content_accountCoop_body_site accountCoop_contents">
                <span class="header_content_accountCoop_body_site_name accountCoop_contents">Google</span>
                <span
                  class="header_content_accountCoop_body_site_id accountCoop_contents"
                  *ngIf="acGoogleMailAddress"
                  >{{ acGoogleMailAddress }}</span
                >
              </div>
              <span
                *ngIf="acGoogle"
                (click)="cancelAccountCoop('Google')"
                class="header_content_accountCoop_body_coopbtn accountCoop_contents"
                [class.release]="acGoogle"
                >連携解除</span
              >
              <span
                *ngIf="!acGoogle"
                (click)="linkAccountCoop('Google')"
                class="header_content_accountCoop_body_coopbtn accountCoop_contents"
                [class.release]="acGoogle"
                >連携する</span
              >
            </div>

            <div class="header_content_accountCoop_body accountCoop_contents last">
              <div class="header_content_accountCoop_body_site accountCoop_contents">
                <span class="header_content_accountCoop_body_site_name accountCoop_contents">Facebook</span>
                <span
                  class="header_content_accountCoop_body_site_id accountCoop_contents"
                  *ngIf="acFacebookMailAddress"
                  >{{ acFacebookMailAddress }}</span
                >
              </div>
              <span
                *ngIf="acFacebook"
                (click)="cancelAccountCoop('Facebook')"
                class="header_content_accountCoop_body_coopbtn accountCoop_contents"
                [class.release]="acFacebook"
                >連携解除</span
              >
              <span
                *ngIf="!acFacebook"
                (click)="linkAccountCoop('Facebook')"
                class="header_content_accountCoop_body_coopbtn accountCoop_contents"
                [class.release]="acFacebook"
                >連携する</span
              >
            </div>
          </div>
        </div>

        <div class="header_content_aboutApp" [class.active]="notificationSettingShowing">
          <!-- モバイルメニュー展開時クローズボタン -->
          <label (click)="closeNotificationDetail()" id="header_content__closeBtn" class="header_content__closeBtn"
            ><span class="black"></span
          ></label>
          <div class="header_content_aboutApp_header">
            <span class="header_content_aboutApp_header_back_btn" (click)="backMyPage()"><</span>
            <span class="header_content_aboutApp_header_text">通知設定</span>
          </div>
          <div class="notification_description">
            お知らせや次回のご提案をお送りしています。<br />
            ご予約に関する通知は、通知設定がOFFでも送信されます。
          </div>
          <!-- iOSのみ -->
          <div class="notification_list" *ngIf="isLineMessagingEnabled && pds.getIsIos()">
            <ion-label>LINE通知</ion-label>
            <ion-button
              *ngIf="lineNotificationStatus == 0"
              size="small"
              color="dark"
              fill="outline"
              class="notification-button"
              (click)="changedLineNotification(true)"
              >連携する</ion-button
            >
            <ion-toggle
              color="success"
              *ngIf="lineNotificationStatus > 0"
              [(ngModel)]="lineNotificationToggle"
              (ionChange)="onChangeLineNotification($event)"
            ></ion-toggle>
          </div>
          <!-- プッシュ通知が使えるバージョンのみ -->
          <div class="notification_list" *ngIf="isSupportedNotification">
            <ion-label>プッシュ通知</ion-label>
            <ion-toggle
              color="success"
              *ngIf="true"
              [(ngModel)]="pwaNotificationToggle"
              (ionChange)="onChangePwaNotification($event)"
            ></ion-toggle>
          </div>
          <hr *ngIf="!isSupportedNotification && isLineMessagingEnabled" class="border_line" />
          <div *ngIf="!isSupportedNotification">
            <div class="notification_list">
              <ion-label>メール通知</ion-label>
              <ion-toggle
                color="success"
                *ngIf="mailNotificationStatus > 0"
                [(ngModel)]="mailNotificationToggle"
                (ionChange)="changedMailNotification($event)"
              ></ion-toggle>
            </div>
            <div class="notification_list">
              <ion-input
                email
                type="email"
                placeholder="メールアドレス"
                class="input_outline"
                [(ngModel)]="inputCustomerMailAddress"
                (ionChange)="onChangeMailAddress($event)"
                #emailCtrl="ngModel"
              ></ion-input>
              <ion-button
                *ngIf="!customerMailAddress"
                size="small"
                color="dark"
                fill="outline"
                class="notification-button"
                (click)="registerAndUpdatedMailAddress()"
                [disabled]="!canRegisterMail || (!emailCtrl.valid && emailCtrl.touched)"
                >登録</ion-button
              >
              <ion-button
                *ngIf="customerMailAddress"
                size="small"
                color="dark"
                fill="outline"
                class="notification-button"
                (click)="registerAndUpdatedMailAddress()"
                [disabled]="!canRegisterMail || (!emailCtrl.valid && emailCtrl.touched)"
                >更新</ion-button
              >
            </div>
            <div class="notification_list red" *ngIf="layoutData.defaultSalonName != null && pds.getIsCompany()">
              ※実在性確認のメールは{{ layoutData.defaultSalonName }}から送信します。
            </div>
            <ion-label class="email-input" *ngIf="!emailCtrl.valid && emailCtrl.touched" color="danger"
              >正しいメールアドレスを入力してください。</ion-label
            >
          </div>
        </div>
        <div class="progress-mask" *ngIf="loading">
          <ion-spinner name="lines"></ion-spinner>
        </div>

        <div class="header_content_aboutApp" [class.active]="isShowAboutApp">
          <!-- モバイルメニュー展開時クローズボタン -->
          <label (click)="closeMyPageAndAboutApp()" id="header_content__closeBtn" class="header_content__closeBtn"
            ><span class="black"></span
          ></label>
          <div class="header_content_aboutApp_header">
            <span class="header_content_aboutApp_header_back_btn" (click)="backMyPage()"><</span>
            <span class="header_content_aboutApp_header_text">アプリについて</span>
          </div>
          <div class="header_content_aboutApp_body scrollable">
            <div class="header_content_aboutApp_body_container section-block scrollable">
              <div class="header_content_aboutApp_body_container title scrollable">バージョン情報</div>
              <div class="header_content_aboutApp_body_container text scrollable">
                {{ this.pds.getData('appVer') }}
              </div>
            </div>
            <div class="header_content_aboutApp_body_container section-block scrollable">
              <div class="header_content_aboutApp_body_container title scrollable">識別番号</div>
              <div class="header_content_aboutApp_body_container text scrollable">
                {{ partialUserToken }}
              </div>
            </div>
            <div class="header_content_aboutApp_body_container section-block scrollable">
              <div class="header_content_aboutApp_body_container title scrollable">このアプリでできること</div>
              <div class="header_content_aboutApp_body_container text scrollable">
                ・空き状況の確認
                <br />
                ・予約する
                <br />
                ・予約変更・キャンセル
                <br />
                ・サロン情報
                <br />
                ・サロンからのお知らせ受信
              </div>
            </div>
            <div *ngIf="pds.getIsIos()" class="header_content_aboutApp_body_container section-block scrollable">
              <div class="header_content_aboutApp_body_container title scrollable">通知について</div>
              <div class="header_content_aboutApp_body_container text scrollable">
                アプリのプッシュ通知はAppleのiOSの仕様上、iOS16.4以降でご利用の場合に対応しています。iOS16.3以前の場合、プッシュ通知は届きません（予約に関するお知らせはアプリ内のメッセージに届きます）。メッセージの見逃し防止のため、予約時にLINEまたはメールで
                <span class="default" (click)="closeAboutAppAndOpenNotification()">通知設定</span
                >をすることをおすすめします。
              </div>
            </div>
            <div class="header_content_aboutApp_body_container section-block scrollable">
              <div class="header_content_aboutApp_body_container title scrollable">アプリ・データの削除</div>
              <div class="header_content_aboutApp_body_container title subtitle scrollable">
                【アプリを削除した場合の履歴の復旧方法】
              </div>
              <div class="header_content_aboutApp_body_container text scrollable marginB20px">
                アプリを削除（アンインストール）してしまった場合は、再インストールして、サロンでハンドシェイク（サロンに登録されているお客様データとアプリの紐付け）を行ってください。
              </div>
              <div class="header_content_aboutApp_body_container title subtitle scrollable">
                【アプリの再インストール方法】
              </div>
              <div class="header_content_aboutApp_body_container text scrollable marginB20px">
                サロンにお問い合わせください。
              </div>
              <div class="header_content_aboutApp_body_container title subtitle scrollable">【アプリデータの削除】</div>
              <div class="header_content_aboutApp_body_container text scrollable">
                データの削除を行うと、予約履歴、メッセージの確認などができなくなります。
                <div class="verticalSpacer small"></div>
                <button *ngIf="!isUrlKey" (click)="clickDeleteAccount()" class="button delete_data_button">
                  アプリデータを削除する
                </button>
              </div>
              <div class="header_content_aboutApp_body_container title subtitle scrollable">お客様情報の削除依頼</div>
              <div class="header_content_aboutApp_body_container text scrollable">
                サロンにお客様情報の削除を依頼します。<br />
                サロンで確認後、お客様情報の削除をします。
                <div class="verticalSpacer small"></div>
                <button *ngIf="!isUrlKey" (click)="clickDeleteCustomerData()" class="button delete_data_button">
                  削除する
                </button>
              </div>
            </div>
            <div class="header_content_aboutApp_body_container section-block scrollable">
              <div class="header_content_aboutApp_body_container title scrollable">お問い合わせ、不具合の報告</div>
              <div class="header_content_aboutApp_body_container text scrollable">
                ご意見・ご要望・不具合などがございましたらサロンまでお知らせください。
              </div>
            </div>
            <!-- 検証環境のみ -->
            <div class="header_content_aboutApp_body_container section-block scrollable" *ngIf="debugIOS16_3Enabled()">
              <div class="header_content_aboutApp_body_container title scrollable">デバッグ機能</div>
              <div class="notification_list">
                <ion-label>iOS16.3にする（デバッグ用）</ion-label>
                <ion-toggle
                  color="success"
                  [(ngModel)]="isDebuggIOS16_3"
                  (ionChange)="onChangeIsDebugIOS16_3($event)"
                ></ion-toggle>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <!-- /.header_container -->
</header>
